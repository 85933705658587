import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import vector from "../images/Source_File_Mark 1 4.png"
import vector2 from "../images/Source_File_Mark 5 White 1.png"
import ContactSection from "../components/map"
import Footer from "../components/footer"
import "../styles/Animation.css"

const Home = probs => {
  return (
    <Layout>
      <Helmet>
        <title>Spice Brew Restaurants | Welcome</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="A modern culinary journey fusing traditional Indian spices, robotic chefs, halal food and a great experience."
        />
        <meta
          name="keywords"
          content="spice brew, ealing, chiswick, london, london food, foodie, indian food, healthy food, deliveroo, just eat, 
          uber eats, street food, insta food, food lover, healthy food delivery, healthy food inspo, vegetarian indian food, 
          veggie food, indian street food, spicy, indian dishes, indian restaurant london, indian cuisine, british indian food,
          indian food delivery london, indian food london"
        />
        <link rel="canonical" href="https://spice-brew.com" />
      </Helmet>

      <section className="xl:px-20 md:px-12 sm:p-8 px-6 lg:py-16 md:py-12 overflow-hidden flex justify-center mb-8 sm:mb-0">
        <div className="xl-max-w-1200 lg-max-w-1000 w-full ">
          <div className="grid grid-cols-4 md:gap-4 gap-2 mt-8 sm:mt-0 ">
            <div
              className="lg:col-span-2 sm:col-span-2 col-span-2 "
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <h1 className="xl:text-4xl lg:text-3xl sm:text-2xl Moderat-Regular xl:w-5/6 lg:w-full sm:w-96 hidden sm:block">
                Re-invented{" "}
                <span className="text-green Moderat-bold">Indian flavours</span>{" "}
                fused with{" "}
                <span className="text-navyBlue Moderat-bold">
                  Modern Western
                </span>{" "}
                ideas
              </h1>
            </div>

            {/* cover img 1 */}

            <div
              className="row-span-2 xl:mt-20 lg:mt-14 sm:mt-24 mt-8"
              data-sal="slide-down"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <Img
                className="xl:w-60 xl:h-80 lg:w-52 lg:h-72 md:rounded-xl rounded-md "
                fluid={probs.data.coverImgOne.childImageSharp.fluid}
              ></Img>
            </div>

            {/* cover img 2 */}

            <div
              className="row-span-2 sm:mt-12  lg:mt-0  mt-0"
              data-sal="slide-down"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <Img
                className="xl:w-60 xl:h-80 lg:w-52 lg:h-72 md:rounded-xl rounded-md"
                fluid={probs.data.coverImgTwo.childImageSharp.fluid}
              ></Img>
            </div>

            {/* cover img 3 */}

            <div
              className=" xl:mt-20 lg:mt-12 sm:mt-32 mt-24"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <Img
                className="xl:w-60 xl:h-80 lg:w-52 lg:h-72 md:rounded-xl rounded-md"
                fluid={probs.data.coverImgThree.childImageSharp.fluid}
              ></Img>
            </div>

            {/* cover img 4 */}

            <div
              className="xl:mt-5 lg:-mt-0 sm:mt-20 mt-16"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <Img
                className="xl:w-60 xl:h-80 lg:w-52 lg:h-72 md:rounded-xl rounded-md "
                fluid={probs.data.coverImgFour.childImageSharp.fluid}
              ></Img>
            </div>
          </div>
          {/*  small device intro title */}
          <div className="mt-6 sm:hidden flex justify-center">
            <h1 className="text-xl Moderat-Regular sm:w-auto w-3/4 sm:hidden">
              Re-invented{" "}
              <span className="text-green Moderat-bold">Indian flavours</span>{" "}
              fused with{" "}
              <span className="text-navyBlue Moderat-bold">Modern Western</span>{" "}
              ideas
            </h1>
          </div>

          <div
            className="flex sm:justify-end justify-center lg:-mt-14 xl:-mt-10 md:-mt-2 mt-8"
            data-sal="flip-right"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="flex flex-col items-center">
              {/* <div className="hr"></div> */}
              {/* <p className="Gilroy-SemiBold sm:text-sm text-xs orderNow-text bg-white sm:-mt-6 -mt-5 px-2 py-3">Order Now</p> */}
              <button className="fancy">
                <span className="top-key"></span>
                <a href="http://spice-brew.square.site" className="capitalize">
                  order now
                </a>
                <span className="bottom-key-1"></span>
                <span className="bottom-key-2"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* 2nd section  */}
      <section className="flex justify-center green-section md:pt-12 pt-12 pb-28 sm:pb-20 sm:h-96 lg:h-auto">
        <div className="xl-max-w-1200 lg-max-w-1000 sm:w-full w-5/6 ">
          <div className="grid sm:grid-cols-2 grid-cols-1 lg:gap-6 sm:gap-4">
            <div className="flex sm:justify-center justify-end hoverEffect">
              <Img
                fluid={probs.data.prdtImgOne.childImageSharp.fluid}
                className="lg:w-72 sm:w-60 lg:h-72 sm:h-60 w-36 h-36 figure"
              />
            </div>
            <div className=" flex justify-center sm:justify-start -mt-16 sm:mt-0">
              <div className=" sm:pr-4 ">
                <img src={vector} alt="" className="h-24" />
                <h2 className="xl:text-xl lg:text-xl sm:text-lg text-base Moderat-Regular text-white lg:w-96 md:w-80 sm:w-auto">
                  Inspired Indian fusion dishes, are our passion.
                </h2>
                <p className="Moderat-Condensed-Regular lg:text-base text-sm text-white lg:w-96 md:w-80 sm:w-auto">
                  Our love of Indian flavours inspired us to create a very
                  modern dining experience. New yet instantly familiar. It
                  brings together what we love about Western food and a respect
                  for our Indian heritage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="polygon"></div>

      {/* 3rd section  */}
      <section className="flex justify-center blue-section md:py-12 py-8 -mt-3">
        <div className="xl-max-w-1200 lg-max-w-1000 sm:w-full w-5/6 ">
          <div className="flex flex-col-reverse sm:grid sm:grid-cols-2 lg:gap-6 sm:gap-4">
            <div className=" flex justify-center sm:justify-center sm:mt-0">
              <div className=" sm:pl-4 ">
                <div className="flex items-center">
                  <img src={vector2} alt="" className="w-24 md:w-32 lg:w-40 " />
                  <h3 className="text-white text-2xl Moderat-bold">
                    Why wait?
                  </h3>
                </div>
                <p className="Moderat-Condensed-Regular lg:text-base text-sm text-white lg:w-96 md:w-80 sm:w-auto">
                  If all this talk about food is making you hungry, click the
                  button to order your Spice Brew food now, or use the map tool
                  below to find your closest Spice Brew restaurant.
                </p>
                <div className="flex">
                  <div className="flex flex-col items-center section-btn lg:mt-14 mt-8">
                    {/* <div className="hr"></div> */}
                    {/* <p className="Gilroy-SemiBold sm:text-sm text-xs orderNow-text bg-navyBlue text-green sm:-mt-6 -mt-5 px-2 py-3">Order Now</p> */}
                    <button className="fancy">
                      <span className="top-key"></span>
                      <a
                        href="http://spice-brew.square.site"
                        className="capitalize"
                      >
                        order now
                      </a>
                      <span className="bottom-key-1"></span>
                      <span className="bottom-key-2"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex sm:justify-center justify-end hoverEffect">
              <Img
                fluid={probs.data.prdtImgTwo.childImageSharp.fluid}
                className="lg:w-72 sm:w-60 lg:h-72 sm:h-60  w-36 h-36 figure"
              />
            </div>
          </div>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </Layout>
  )
}

export default Home
export const pageQuery = graphql`
  query {
    coverImgOne: file(relativePath: { eq: "Rectangle-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgTwo: file(relativePath: { eq: "Rectangle-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgThree: file(relativePath: { eq: "Rectangle-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgFour: file(relativePath: { eq: "Rectangle-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    prdtImgOne: file(relativePath: { eq: "Rectangle 23.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    prdtImgTwo: file(relativePath: { eq: "Rectangle-25.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
